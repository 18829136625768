import styled from "styled-components";

const Titles = ({ content }) => {
  return (
    <>
      <Header>{content}</Header>
    </>
  );
};
export default Titles;

const Header = styled.div`
  font-size: max(3.4vw, 4.6rem);
  text-decoration: underline;
`;
